import React from "react";

import { useStyletron } from "baseui";
import { Heading, HeadingLevel } from "baseui/heading";
import { Paragraph1, Label1 } from "baseui/typography";
import { StyledLink } from "baseui/link";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { Checkbox } from "baseui/checkbox";

import Page from "../../components/internal-page";
import CheckNPD from "../../components/check-npd";

const Apply = ({ location }) => {
  const [css] = useStyletron();
  const mt5 = css({
    marginTop: `5px`,
  });
  const [value, setValue] = React.useState(location?.state?.id || "1");
  const [checked, setChecked] = React.useState(false);
  return (
    <Page title="Подать заявку" path="/apply" parent="apply">
      <HeadingLevel>
        <RadioGroup
          value={value}
          onChange={(e) => setValue(e.target.value)}
          name="number"
          align={ALIGN.horizontal}
        >
          <Radio value="1">Юридическое лицо</Radio>
          <Radio value="2">Индивидуальный предприниматель</Radio>
          <Radio value="3">Самозанятый</Radio>
        </RadioGroup>

        <div className={mt5}>
          <Checkbox
            checked={checked}
            disabled={value === "3"}
            onChange={() => setChecked(!checked)}
          >
            Заём более 500&nbsp;000 &#8381;, есть залоговое имущество
          </Checkbox>
        </div>

        <Paragraph1></Paragraph1>
        {value === "2" && (
          <>
            <Label1>Индивидуальному предпринимателю</Label1>
            <Paragraph1>
              <StyledLink
                href="https://drive.google.com/file/d/1tBIkS0gNkE2Lo3an7lBFkZld71XaY1dI/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Заявление-анкета для ИП
              </StyledLink>
              {!checked && (
                <>
                  {" "}
                  /{" "}
                  <StyledLink
                    href="https://drive.google.com/file/d/1fIolPh0PhJZAvInW5bx8UklSwbVa_jhn/view"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Заявление-анкета для ИП (экспресс-заём)
                  </StyledLink>
                </>
              )}
            </Paragraph1>
            {!checked && (
              <>
                <Paragraph1>
                  <StyledLink
                    href="https://drive.google.com/file/d/1bhqo0BSDZz50s1x07LyW8wLvjmdado_l/view"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Перечень документов для ИП до 500 тысяч рублей
                  </StyledLink>
                </Paragraph1>
              </>
            )}{" "}
            {checked && (
              <Paragraph1>
                <StyledLink
                  href="https://drive.google.com/file/d/1Vqey-inNUDAXcTmve2HhuoGI9KqWvFAX/view"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Перечень документов для ИП более 500 тысяч рублей
                </StyledLink>
              </Paragraph1>
            )}
          </>
        )}

        {value === "1" && (
          <>
            <Label1>Юридическому лицу</Label1>
            <Paragraph1>
              <StyledLink
                href="https://drive.google.com/file/d/1tBIkS0gNkE2Lo3an7lBFkZld71XaY1dI/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Заявление-анкета для юридических лиц
              </StyledLink>
            </Paragraph1>
            <Paragraph1>
              <StyledLink
                href="https://drive.google.com/file/d/1r4BwvTNiIbUf0uC0-UH2TrTIknj7Kx7i/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Перечень документов для юридических лиц
              </StyledLink>
            </Paragraph1>
          </>
        )}
        {value === "3" && (
          <>
            <Label1>Самозанятым гражданам *</Label1>
            <Paragraph1>
              <em>
                * Самозанятые граждане – физические лица, применяющие
                специальный налоговый режим «Налог на профессиональный доход».
                Подробнее на сайте налоговой{" "}
                <StyledLink
                  href="https://npd.nalog.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  npd.nalog.ru
                </StyledLink>
              </em>
            </Paragraph1>
            <CheckNPD title="Проверить возможность получения микрозайма" />
            <Label1>Документы для заявки</Label1>
            <Paragraph1>
              <StyledLink
                href="https://drive.google.com/file/d/1rVDmLrvvHM0AfkWusDsweeai8oDJ2cYg/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Заявление-анкета для самозанятых
              </StyledLink>
            </Paragraph1>
            <Paragraph1>
              <StyledLink
                href="https://drive.google.com/file/d/1UqNPEGJl6whLWel3eCdBOTzep86CN9XM/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Перечень документов для самозанятых граждан
              </StyledLink>
            </Paragraph1>
          </>
        )}
        <Label1>Для поручителей – физических и юридических лиц</Label1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1L9BPZIWn4Zj_iIp8lKwurNyavXn8iWYa/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Перечень документов по поручительству
          </StyledLink>
        </Paragraph1>

        {checked && value !== "3" && (
          <>
            <Label1>Для займов с залогом</Label1>
            <Paragraph1>
              <StyledLink
                href="https://drive.google.com/file/d/1A1kvrL-nMJC9w9u6Sc5a5EbHoo3M4g7C/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Перечень документов по обеспечению возврата микрозайма
              </StyledLink>
            </Paragraph1>
          </>
        )}
        <br />
        <Label1>Бланки справок, заявлений и согласий</Label1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1cnFo2uKA-57rc6ey_v5vuVFMBUXV8u5s/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Заявление на льготный период
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1s3-5y0LqLoGRkhDk_B0G707CMQWh_-H8/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Заявление на "кредитные каникулы"
          </StyledLink>
        </Paragraph1>
        {value !== "3" && (
          <Paragraph1>
            <StyledLink
              href="https://drive.google.com/file/d/1wujh0-C0AZVq_dNfUnR56cEV_xrtngO6/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              Справка о сохранении рабочих мест
            </StyledLink>
          </Paragraph1>
        )}
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1TAEOIi2IBdVdwv6Et_IQeAXDfc78U71D/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Согласие на обработку персональных данных
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1jYGzP0-3uF8u7FyoH9fE88rekIFuADGY/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Согласие физического лица на получение отчета в НБКИ
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1_2-ZneBYx68pTwXygpukkyQXZrx4Ruof/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Согласие юридического лица на получение отчета в НБКИ
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/17H0x4z8u06T1PbJFlPc2ZgjTBBQjRJi2/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Заявление о реквизитах расчетного счета
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/17gaFVPFJxxtaJ7YweAf4xhAuhZdQ5qg5/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Резюме проекта по программе 5-5-10
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1TsGBymIRKpmsPEtHkmtQC1sPlcq2BO8X/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Расчетный баланс
          </StyledLink>
        </Paragraph1>
      </HeadingLevel>
    </Page>
  );
};

export default Apply;
